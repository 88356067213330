import vitusETRMapi from "./apiConfig";
import { endpoints } from "./endpoints";

export const getBooks = async () => {
  return vitusETRMapi.get(endpoints.getBook);
};

export const saveBooks = async (body) => {
  return vitusETRMapi.post(endpoints.saveBook, body);
};

export const deleteBook = async (body) => {
  return vitusETRMapi.post(endpoints.deleteBook, body);
};

export const getBookDetails = async () => {
  return vitusETRMapi.get(endpoints.getBookDetails);
};

export const getMarkets = async (filter) => {
  return vitusETRMapi.post(endpoints.getMarkets, filter);
};

export const getMarketNames = async () => {
  return vitusETRMapi.get(endpoints.getMarketNames);
};

export const saveMarket = async (body) => {
  return vitusETRMapi.post(endpoints.saveMarket, body);
};

export const deleteMarket = async (body) => {
  return vitusETRMapi.post(endpoints.deleteMarket, body);
};

export const getCompanies = async () => {
  return vitusETRMapi.get(endpoints.getCompanies);
};

export const getCompaniesDetail = async () => {
  return vitusETRMapi.get(endpoints.getCompaniesDetail);
};

export const saveCompany = async (body) => {
  return vitusETRMapi.post(endpoints.saveCompany, body);
};

export const deleteCompany = async (body) => {
  return vitusETRMapi.post(endpoints.deleteCompany, body);
};

export const getContractTypes = async () => {
  return vitusETRMapi.get(endpoints.getContractTypes);
};

export const saveContractType = async (body) => {
  return vitusETRMapi.post(endpoints.saveContractType, body);
};

export const deleteContractType = async (body) => {
  return vitusETRMapi.post(endpoints.deleteContractType, body);
};

export const getPeriods = async () => {
  return vitusETRMapi.get(endpoints.getPeriods);
};

export const getPeriodsDetail = async () => {
  return vitusETRMapi.get(endpoints.getPeriodsDetail);
};

export const savePeriod = async (body) => {
  return vitusETRMapi.post(endpoints.savePeriod, body);
};

export const deletePeriod = async (body) => {
  return vitusETRMapi.post(endpoints.deletePeriod, body);
};

export const getPriceCurve = async () => {
  return vitusETRMapi.get(endpoints.getPriceCurve);
};

export const getPriceCurvesDetail = async () => {
  return vitusETRMapi.get(endpoints.getPriceCurvesDetail);
};

export const savePriceCurve = async (body) => {
  return vitusETRMapi.post(endpoints.savePriceCurve, body);
};

export const deletePriceCurve = async (body) => {
  return vitusETRMapi.post(endpoints.deletePriceCurve, body);
};

export const getContractCountry = async () => {
  return vitusETRMapi.get(endpoints.getContractCountry);
};

export const uploadFinancialMarkFile = async (body) => {
  return vitusETRMapi.post(endpoints.uploadFinancialMarkFile, body);
};

export const deleteFinancialMarkFile = async (body) => {
  return vitusETRMapi.post(endpoints.deleteFinancialMarkFile, body);
};

export const getCost = async () => {
  return vitusETRMapi.get(endpoints.getCost);
};

export const getCostsDetail = async () => {
  return vitusETRMapi.get(endpoints.getCostsDetail);
};

export const saveCost = async (body) => {
  return vitusETRMapi.post(endpoints.saveCost, body);
};

export const getCountry = async () => {
  return vitusETRMapi.get(endpoints.getCountry);
};

export const deleteCost = async (body) => {
  return vitusETRMapi.post(endpoints.deleteCost, body);
};

export const getContracts = async () => {
  return vitusETRMapi.get(endpoints.getContracts);
};

export const getContract = async (filter) => {
  return vitusETRMapi.get(endpoints.getContract, { params: filter });
};

export const saveContract = async (body) => {
  return vitusETRMapi.post(endpoints.saveContract, body);
};

export const deleteContract = async (body) => {
  return vitusETRMapi.post(endpoints.deleteContract, body);
};

export const getBackOfficeNotificatons = async () => {
  return vitusETRMapi.get(endpoints.getBackOfficeNotificatons);
};

export const getDocuments = async (filter) => {
  return vitusETRMapi.get(endpoints.getDocuments, { params: filter });
};

export const saveDocument = async (body) => {
  return vitusETRMapi.post(endpoints.saveDocument, body);
};

export const deleteDocument = async (body) => {
  return vitusETRMapi.post(endpoints.deleteDocument, body);
};

export const getCollaterals = async (filter) => {
  return vitusETRMapi.get(endpoints.getCollaterals, { params: filter });
};

export const saveCollateral = async (body) => {
  return vitusETRMapi.post(endpoints.saveCollateral, body);
};

export const deleteCollateral = async (body) => {
  return vitusETRMapi.post(endpoints.deleteCollateral, body);
};

export const getEtrmPnl = async (body) => {
  return vitusETRMapi.post(endpoints.getPnl, body);
};

export const getEtrmPnlV2 = async (body) => {
  return vitusETRMapi.post(endpoints.getPnlV2, body);
};

export const getCompanySummaryPnl = async (body) => {
  return vitusETRMapi.post(endpoints.getCompanySummaryPnl, body);
};

export const downloadCompanySummaryPnl = async (body) => {
  return vitusETRMapi.post(endpoints.downloadCompanySummaryPnl, body, {responseType: 'arraybuffer'});
}

export const importCompanies = async (body) => {
  return vitusETRMapi.post(endpoints.importCompanies, body);
};

export const importContracts = async (body) => {
  return vitusETRMapi.post(endpoints.importContracts, body);
};

export const getBilling = async (body) => {
  return vitusETRMapi.post(endpoints.getBilling, body);
};

export const getNotifications = async (filter) => {
  return vitusETRMapi.get(endpoints.getNotifications, { params: filter });
};

export const markAsSeen = async (body) => {
  return vitusETRMapi.post(endpoints.markAsSeen, body);
};

export const getContractPnl = async (body) => {
  return vitusETRMapi.post(endpoints.getContractPnl, body);
};


export const getLTPosition = async (body) => {
  return vitusETRMapi.post(endpoints.getLTPosition, body);
};

export const downloadPosition = async (body) => {
  return vitusETRMapi.post(endpoints.downloadPosition, body, {responseType: 'arraybuffer'});
}

export const getPosAndMark = async (body) => {
  return vitusETRMapi.post(endpoints.getPosAndMark, body);
};

export const downloadPosAndMark = async (body) => {
  return vitusETRMapi.post(endpoints.downloadPosAndMark, body, {responseType: 'arraybuffer'});
}

export const getPnlStatus = async (filter) => {
  return vitusETRMapi.get(endpoints.getPnlStatus, { params: filter });
};

export const getDistribution = async (body) => {
  return vitusETRMapi.post(endpoints.getDistribution, body);
};

export const saveDistribution = async (body) => {
  return vitusETRMapi.post(endpoints.saveDistribution, body);
};

export const sendCompanySummaryPnl = async (body) => {
  return vitusETRMapi.post(endpoints.sendCompanySummaryPnl, body);
}

export const sendPosAndMark = async (body) => {
  return vitusETRMapi.post(endpoints.sendPosAndMark, body);
}

export const sendPosition = async (body) => {
  return vitusETRMapi.post(endpoints.sendPosition, body);
}

export const getEtrmLiquidation = async (body) => {
  return vitusETRMapi.post(endpoints.getEtrmLiquidation, body);
};

export const downloadEtrmLiquidation = async (body) => {
  return vitusETRMapi.post(endpoints.downloadEtrmLiquidation, body, {responseType: 'arraybuffer'});
};

export const sendEtrmLiquidation = async (body) => {
  return vitusETRMapi.post(endpoints.sendEtrmLiquidation, body);
};

export const getApiContracts = async () => {
  return vitusETRMapi.get(endpoints.getApiContracts);
};

export const getApiContract = async (filter) => {
  return vitusETRMapi.get(endpoints.getApiContract, { params: filter });
};

export const deleteApiContract = async (body) => {
  return vitusETRMapi.post(endpoints.deleteApiContract, body);
};

export const getEtrmCurrencies = async (body) => {
  return vitusETRMapi.post(endpoints.getEtrmCurrencies, body);
};

export const downloadTotalSummaryPnl = async (body) => {
  return vitusETRMapi.post(endpoints.downloadTotalSummaryPnl, body, {responseType: 'arraybuffer'});
};

export const sendTotalSummaryPnl = async (body) => {
  return vitusETRMapi.post(endpoints.sendTotalSummaryPnl, body);
};

export const saveApiContracts = async (body) => {
  return vitusETRMapi.post(endpoints.saveApiContracts, body);
};