import './TTable.css';

import React from 'react';
import DataGrid, { SelectColumn } from 'react-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { lowerCase, uniq, orderBy, isEmpty } from 'lodash';

import { exportToXlsx } from './TTableExportUtils';
import MultipleSelect from '../MultipleSelect/MultipleSelect';

class TTable extends React.Component {

    filterValueNotSet = v => v === "" || v === "all" || v.lenght ? v.lenght === 0 : false;

    customColumnParameters = {
        'TfilterType': 'TfilterType'
    };

    sortDirections = {
        'none': 'NONE',
        'asc': 'ASC',
        'desc': 'DESC'
    };

    state = {
        columns: this.props.columns,
        rows: this.props.rows,
        sortDirection: this.sortDirections.none,
        sortColumn: null,
        enableFilterRow: false,
        filters: {},
        defaultFilters: {},
        filteredRows: this.props.rows,
        selectedRows: new Set()
    };

    componentDidMount() {
        this.setTableMetaData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.rows !== prevProps.rows) {
            this.setState({
                rows: this.props.rows,
                columns: this.props.columns,
                filteredRows: this.props.rows,
            }, () => this.setTableMetaData());
        }
    }

    /*
         Table column and row properties are wrapped by using TTable component. "setTableMetaData" method checks wrapped 
        content and converts into original component properties.
    */
    setTableMetaData() {
        if (this.props.columns) {

            let enableFilterRow = false;
            let defaultFilters = {};

            // * MULTIPLE ROW SELECTION SETTINGS
            let arrangedColumns = this.setColumnSelection();

            arrangedColumns = arrangedColumns.map(column => {

                // * FILTER SETTINGS
                if (this.customColumnParameters.TfilterType in column) {

                    // Enable filter row
                    enableFilterRow = true;

                    let filterType = column[this.customColumnParameters.TfilterType];

                    this.setDefaultFilterValues(filterType, defaultFilters, column.key);

                    return this.createColumnObj(
                        column.key,
                        column.name,
                        this.getFilterContent(filterType, column.key),
                        column.sortable ? column.sortable : false,
                        column.resizable ? column.resizable : false,
                        column.frozen ? column.frozen : false,
                        column.width ? column.width : undefined,
                        column.summaryFormatter ? column.summaryFormatter : null
                    );

                } else {
                    return column;
                }
            });

            let tableDataWithRowActions = {};

            // * EDIT/DELETE ROW SETTINGS
            if (this.props.showEditRow || this.props.showDeleteRow || this.props.showAddRow)
                tableDataWithRowActions = this.setEditDeleteRow(arrangedColumns)

            if (isEmpty(tableDataWithRowActions))
                this.setState({ columns: arrangedColumns, enableFilterRow, defaultFilters, filters: defaultFilters });
            else
                this.setState({
                    columns: tableDataWithRowActions.columns,
                    rows: tableDataWithRowActions.rows,
                    filteredRows: tableDataWithRowActions.rows,
                    enableFilterRow,
                    defaultFilters,
                    filters: defaultFilters
                });
        }
    }

    setEditDeleteRow(arrangedColumns) {
        let columns = [...arrangedColumns, { key: 'editDeleteRow', name: '', width: this.props.showAddRow ? 100 : 20}];;

        let rows = this.props.rows.map(r => {
            return {
                ...r,
                editDeleteRow: (
                    <div>
                        {this.props.showEditRow ? <EditIcon style={{ margin: "5px" }} onClick={() => this.onEditRowClick(r)}></EditIcon> : null}
                        {this.props.showDeleteRow ? <DeleteIcon style={{ margin: "5px" }} onClick={() => this.onDeleteRowClick(r)}></DeleteIcon> : null}
                        {this.props.showAddRow ? <AddIcon style={{ margin: "5px" }} onClick={() => this.onAddRowClick(r)}></AddIcon> : null}
                    </div>

                )
            };
        });
        return { columns, rows };

    }


    setColumnSelection() {

        if (this.props.showSelectionColumn) {
            if (this.props.selectedRowKey === '')
                throw new Error('selectedRowKey prop not set!');

            return [SelectColumn, ...this.props.columns];

        } else {
            return [...this.props.columns];
        }
    }

    // Default input value: '', Default select value: 'all' 
    setDefaultFilterValues(filterType, filters, columnKey) {
        switch (filterType) {

            case TfilterTypes.input:
                filters[columnKey] = '';
                break;

            case TfilterTypes.select:
                filters[columnKey] = 'All';
                break;

            case TfilterTypes.multiSelect:
                if (this.props.columnsExcluded.includes(columnKey)){
                    break;
                }
                filters[columnKey] = orderBy(uniq(this.props.rows.map(j => j[columnKey])));
                break;

            case TfilterTypes.multiSelectNotOrdered:
                if (this.props.columnsExcluded.includes(columnKey)){
                    break;
                }
                filters[columnKey] = (uniq(this.props.rows.map(j => j[columnKey])));
                break;
    
            default:
                break;
        }
    }

    onChangeMultiSelect = (items, columnKey) => {
        let itemSet = new Set(items.map(i => i.value))
        let itemList = Array.from(itemSet)
        let filteredRows = this.state.filteredRows.filter(r => itemList.includes(r[columnKey]))

        let newFilter = { ...this.state.filters }
        newFilter[columnKey] = itemList
        this.setState({ filteredRows: filteredRows, filters: newFilter }, () => {
            this.setTableMetaData()
            this.handleFilterChange(newFilter)
        })

    }



    getFilterContent(filterType, columnKey) {

        switch (filterType) {

            case TfilterTypes.input:
                return p => (
                    <div className="t-table-filter-container">
                        <input
                            className="t-table-filter"
                            value={p.value}
                            onChange={e => p.onChange(e.target.value)}
                        />
                    </div>
                );

            case TfilterTypes.select:

                if (!columnKey)
                    throw new Error('Column key not found!');

                let filterContent = ['ALL', ...orderBy(uniq(this.props.rows.map(j => j[columnKey])))];

                return p => (
                    <div className="t-table-filter-container">
                        <select className="t-table-filter" value={p.value} onChange={e => p.onChange(e.target.value)}>
                            {filterContent.map(i => <option key={i ? i : ''} value={lowerCase(i) === 'all' ? '' : i}>{i}</option>)}
                        </select>
                    </div>
                );

            case TfilterTypes.multiSelect:

                if (!columnKey)
                    throw new Error('Column key not found!');

                let filterMultiContent = orderBy(uniq(this.props.rows.map(j => j[columnKey])));
                let selectedItems = filterMultiContent.filter(j => this.state.filters[columnKey] ? this.state.filters[columnKey].includes(j) : true);
                
                return p => (
                    <div className="t-table-filter-container">
                        <MultipleSelect
                            title=""
                            items={filterMultiContent.map(v => ({ value: v, label: v }))}
                            onChange={e => this.onChangeMultiSelect(e, columnKey)}
                            selectedItems={selectedItems.map(v => ({ value: v, label: v }))}
                        >
                        </MultipleSelect>
                    </div>

                );

            case TfilterTypes.multiSelectNotOrdered:
                
                if (!columnKey)
                    throw new Error('Column key not found!');

                let filterMultiNotOrderedContent = (uniq(this.props.rows.map(j => j[columnKey])));
                let selectedNotOrderedItems = filterMultiNotOrderedContent.filter(j => this.state.filters[columnKey] ? this.state.filters[columnKey].includes(j) : true);
                
                return p => (
                    <div className="t-table-filter-container">
                        <MultipleSelect
                            title=""
                            items={filterMultiNotOrderedContent.map(v => ({ value: v, label: v }))}
                            onChange={e => this.onChangeMultiSelect(e, columnKey)}
                            selectedItems={selectedNotOrderedItems.map(v => ({ value: v, label: v }))}
                        >
                        </MultipleSelect>
                    </div>

                );

            default:
                return <div></div>;
        }
    }

    createColumnObj(key, name, filterRenderer, sortable, resizable, frozen, width, summaryFormatter) {
        let common = { key, name, filterRenderer, sortable, resizable, frozen, summaryFormatter };

        return width ? { ...common, width } : common;
    }

    onAddRowClick = row => {
        if (this.props.onAddRowClick)
            this.props.onAddRowClick(row);
    }

    onEditRowClick = row => {
        if (this.props.onEditRowClick)
            this.props.onEditRowClick(row);
    }

    onDeleteRowClick = row => {
        if (this.props.onDeleteRowClick)
            this.props.onDeleteRowClick(row);
    }

    handleSort = (columnKey, direction) => {
        const comparer = (a, b) => {
            if (direction === this.sortDirections.asc) {
                return a[columnKey] > b[columnKey] ? 1 : -1;
            } else if (direction === this.sortDirections.desc) {
                return a[columnKey] < b[columnKey] ? 1 : -1;
            }
        };

        if (direction === this.sortDirections.none) {
            this.setState({ sortDirection: direction, sortColumn: columnKey });
        } else {

            let sortedRows = [...this.state.filteredRows];
            sortedRows = sortedRows.sort(comparer);

            this.setState({
                filteredRows: sortedRows,
                sortDirection: direction,
                sortColumn: columnKey
            });
        }

    }

    handleFilterChange = currentFilter => {
        // If all filters set as their default values
        if (Object.values(currentFilter).every(this.filterValueNotSet)) {
            this.setState({ filteredRows: this.state.rows, filters: this.state.defaultFilters });
        } else {
            let filteredRows = [...this.state.rows];

            Object.keys(currentFilter).forEach(id => {

                if (Array.isArray(currentFilter[id])) {
                    filteredRows = filteredRows.filter(r => currentFilter[id].includes(r[id]));
                } else {
                    let val = lowerCase(currentFilter[id]);

                    // If any selection is 'all' or any input is empty, no need to filter
                    if (val !== "" && val !== 'all')
                        filteredRows = filteredRows.filter(r => lowerCase(r[id]).includes(val));
                }
            });

            this.setState({ filteredRows, filters: currentFilter });
        }

    }

    setSelectedRows = selectedRows => {
        this.setState({ selectedRows });

        if (this.props.onSelectedRows) {
            let all = [...selectedRows];
            let latest = all[all.length - 1];
            this.props.onSelectedRows({ all, latest });
        }
    }

    rowKeyGetter = row => row[this.props.selectedRowKey];

    clearFilters = () => this.setState({ filters: this.state.defaultFilters, filteredRows: this.state.rows });

    toggleFilters = () => this.setState({ enableFilterRow: !this.state.enableFilterRow });

    onSave = () => {

        if (this.props.onSave){
            this.setState({selectedRows: new Set()}, () => this.props.onSave());
        }

    }

    renderTableButtons() {
        let rows = [...this.state.filteredRows, ...(this.props.subTotalAgg ? this.props.subTotalAgg(this.state.filteredRows) : [])]
        return (
            <div className="t-table-toolbar">
                {
                    this.props.showFilterButtons &&
                    <React.Fragment>
                        <button type="button" className="t-button small" onClick={this.toggleFilters}>Toggle Filters</button>
                        <button type="button" className="t-button small" onClick={this.clearFilters}>Clear Filters</button>
                    </React.Fragment>
                }
                {
                    this.props.showExportExcelButton &&
                    <button type="button" className="t-button small" onClick={() => { exportToXlsx(this.state.columns, rows, this.props.exportExcelFileName); }}>
                        Export to XLSX
                    </button>
                }
                {
                    this.props.showSaveButton &&
                    <button type="button" className="t-button small" onClick={this.onSave}>
                        Save
                    </button>
                }
            </div>
        );
    }

    onRowClick = rowId => {
        if (this.props.onRowClick) {
            let row = this.state.filteredRows.length > 0 ? this.state.filteredRows[rowId] : this.state.rows[rowId]
            this.props.onRowClick(row)
        }
    }

    renderTable() {
        let sub_total = []
        if (this.props.subTotalAgg) {
            sub_total = this.props.subTotalAgg(this.state.filteredRows)
        }

        return (
            <div>
                {
                    this.props.selectedRowKey ?
                        <DataGrid
                            style={{ height: this.props.height ? this.props.height : "700px" }}
                            rowKeyGetter={this.rowKeyGetter}
                            selectedRows={this.state.selectedRows}
                            onSelectedRowsChange={this.setSelectedRows}
                            columns={this.state.columns}
                            rows={[...this.state.filteredRows, ...sub_total]}
                            sortDirection={this.state.sortDirection}
                            sortColumn={this.state.sortColumn}
                            onSort={this.handleSort}
                            enableFilterRow={this.state.enableFilterRow}
                            filters={this.state.filters}
                            onFiltersChange={this.handleFilterChange}
                            onRowClick={this.onRowClick}
                            summaryRows={this.props.summaryRows}
                            emptyRowsRenderer={EmptyRowsView}
                        />
                        :
                        <DataGrid
                            style={{ height: this.props.height ? this.props.height : "700px" }}
                            columns={this.state.columns}
                            rows={[...this.state.filteredRows, ...sub_total]}
                            sortDirection={this.state.sortDirection}
                            sortColumn={this.state.sortColumn}
                            onSort={this.handleSort}
                            enableFilterRow={this.state.enableFilterRow}
                            filters={this.state.filters}
                            onFiltersChange={this.handleFilterChange}
                            onRowClick={this.onRowClick}
                            summaryRows={this.props.summaryRows}
                            emptyRowsRenderer={EmptyRowsView}
                        />
                }
            </div>

        );
    }

    render() {
        return (
            <div key="aaa" className="t-table-root">
                {this.renderTableButtons()}
                {this.renderTable()}
            </div >
        );
    }

}

const EmptyRowsView = () => {
    const message = "No data to show";
    return (
        <div
            style={{ textAlign: "center", padding: "100px" }}
        >
            <h3>{message}</h3>
        </div>
    );
};

TTable.defaultProps = {
    columns: [],
    rows: [],
    enableFilterRow: false,
    showSelectionColumn: false,
    showFilterButtons: false,
    selectedRowKey: '',
    showExportExcelButton: false,
    exportExcelFileName: '',
    showEditRow: undefined,
    showDeleteRow: undefined,
    showAddRow: undefined,
    showSaveButton: false,
    columnsExcluded: []
};

export default TTable;

export const TfilterTypes = {
    'input': 'input',
    'select': 'select',
    'multiSelect': 'multiSelect',
    'multiSelectNotOrdered': 'multiSelectNotOrdered'
};