
import React from 'react';
import { isEmpty, orderBy } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { getIntradayResults, saveIntradayResults, deleteIntradayResults } from '../../apis/vitusApi';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTable, { AggType } from '../../components/VTable/VTable';
import { portalMessages } from '../../helpers/portalMessages';
import { handleApiError, alertError, alertSuccess, alertWarning, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDayAheadForGivenDate, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import VFileUploadIcon from '../../components/VFileUploadIcon/VFileUploadIcon';
import { IntradayResultsExcelHelper } from '../IntradayResults/IntradayResultsExcelHelper';

class IntradayResults extends React.Component {

    maxCapacity = 1000;
    maxPrice = 1000;
    spinner = new SpinnerManager(history.location.pathname);
    maxDate = getDayAheadForGivenDate(new Date());
    tableHourColumnClass = { 0: "v-column-narrow-bold" };
    headerClasses = ['v-colored-header-v1', 'v-colored-header-v2'];
    tableRef = React.createRef();
    counterPartyOptions = [
        { value: "Energovia", label: "Energovia" },
        { value: "Ensco", label: "Ensco" },
        { value: "Monolith Capital", label: "Monolith Capital" },
        { value: "Vitus Capital", label: "Vitus Capital" }
    ];

    tableSubheaders = {
        'hour': 'Hour',
        'cet': 'CET',
        'buyCapacity': 'Buy',
        'sellCapacity': 'Sell',
        'dam': 'DAM',
        'buyPrice': 'Buy',
        'sellPrice': 'Sell',
        'unit': 'MWh',
        'currency': 'EUR',
        'capacity': 'Capacity',
        'price': 'Price'
    };

    tableCountryInfo = {
        'country': '',
        'intradayResultId': '',
        'exchange': '',
        'buyCapacityIndex': 0,
        'sellCapacityIndex': 0,
        'damIndex': 0,
        'buyPriceIndex': 0,
        'sellPriceIndex': 0
    };

    tableSpecificColumnList = ["Volume", "Price"];

    state = {
        selectedDate: getDayAheadForGivenDate(new Date()),
        showNeedRefreshModal: false,
        showAddDirectionModal: false,
        activeFilter: [],
        latestRequestedFilter: {},
        disableFilter: false,
        selectedCounterParty: this.counterPartyOptions.find(i => i.value === 'Energovia'),
        selectedVTabDirection: '',
        profileTable: {},
        resultTable: {},
        resultTableByExcel: {},
        capitalResultTable: {},
        cPartyTags: [],
        directionsOfCounterParties: {},
        cParties: [],
        selectedCurrency: null,
    };

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        MissingParameter: {
            "requested_date": portalMessages.DATE_SELECTION
        },
        InvalidData: {
            "requested_date": portalMessages.INVALIED_REQUESTED_DATE,
            "sell_price": portalMessages.INTRADAY_RESULTS.INVALID_PRICE.replace('[PRICE]', this.maxPrice),
            "buy_price": portalMessages.INTRADAY_RESULTS.INVALID_PRICE.replace('[PRICE]', this.maxPrice),
            "sell_capacity": portalMessages.INTRADAY_RESULTS.INVALID_CAPACITY.replace('[CAPACITY]', this.maxCapacity),
            "buy_capacity": portalMessages.INTRADAY_RESULTS.INVALID_CAPACITY.replace('[CAPACITY]', this.maxCapacity)
        }
    };

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    componentDidMount() {
        this.callGetIntradayResultsAsync(this.createGetIntradayResultRequestBody());
    }

    callGetIntradayResultsAsync = async (requestBody) => {

        this.spinner.showSpinner('getIntradayResults');

        try {
            let response;

            try {
                response = await getIntradayResults(requestBody);
            } catch (error) {
                handleApiError(error);
            }

            if (response.data.success) {

                this.setState({
                    resultTableByExcel: {},
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                        { label: "Counter Party", value: requestBody.filter.counter_party },
                    ]
                });

                this.setTables(response.data.success);

            } else if (response.data.error.default.NotFound) {

                alertWarning(portalMessages.INTRADAY_RESULTS.NO_RESULT_FOUND);

                this.setState({
                    profileTable: {},
                    resultTable: {},
                    resultTableByExcel: {},
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                        { label: "Counter Party", value: requestBody.filter.counter_party }
                    ]
                });

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                this.setState({ showNeedRefreshModal: true });
            }


        } finally {
            this.spinner.hideSpinner('getIntradayResults');
        }

    }

    createGetIntradayResultRequestBody(requestedDate = null) {
        return {
            filter: {
                requested_date: requestedDate ? requestedDate : extractDate(this.state.selectedDate),
                counter_party: this.state.selectedCounterParty.value,
            }
        };
    }

    setTables(response) {
        this.spinner.showSpinner('setTables');

        let profileTable = isEmpty(response['intraday_profiles']) ? [] : this.convertJsonToProfileTable(response['intraday_profiles']);
        let resultTable = this.convertJsonToResultTable(response['intraday_results']);

        this.setState({ profileTable, resultTable }, () => this.spinner.hideSpinner('setTables'));
    }


    convertJsonToProfileTable(allCountries) {

        let headers = [[{name: this.tableSubheaders.hour, html: this.tableSubheaders.hour}], [this.tableSubheaders.cet], [''], ['']];
        let values = [];

        allCountries[0].data.forEach(hourly => {
            values.push([hourly.hour + 1]);
        });

        orderBy(allCountries, ['country']).forEach(country => {
            if (! isEmpty(country.data)){
                headers[0].push({name: country.country, html: country.country, agg: AggType.sum});
                headers[0].push({name: '', html: '', agg: AggType.sum});
                headers[0].push({name: '', html: '', agg: AggType.avg});

                headers[1].push(this.tableSubheaders.capacity);
                headers[1].push(this.tableSubheaders.capacity);
                headers[1].push(this.tableSubheaders.price);

                headers[2].push(this.tableSubheaders.buyCapacity);
                headers[2].push(this.tableSubheaders.sellCapacity);
                headers[2].push(this.tableSubheaders.dam);

                headers[3].push(this.tableSubheaders.unit);
                headers[3].push(this.tableSubheaders.unit);
                headers[3].push(this.tableSubheaders.currency);

                country.data.forEach(hourly => {
                    values[hourly.hour].push(hourly.buy_capacity === '' ? '' : parseFloat(hourly.buy_capacity).toFixed(1));
                    values[hourly.hour].push(hourly.sell_capacity === '' ? '' : parseFloat(hourly.sell_capacity).toFixed(1));
                    values[hourly.hour].push(hourly.dap === '' ? '' : parseFloat(hourly.dap).toFixed(2));
                });
            }

        });

        return { headers, values };
    }

    convertJsonToResultTable(allCountries) {

        let headers = [[{name: this.tableSubheaders.hour, html: this.tableSubheaders.hour}], [this.tableSubheaders.cet], [''], ['']];
        let values = [];
        let intradayResultDetails = [];
        let index = 0;
        let tableCountryInfo = this.tableCountryInfo;

        allCountries[0].data.forEach(hourly => {
            values.push([hourly.hour + 1]);
        });

        orderBy(allCountries, ['country']).forEach(country => {
            let info = Object.create(tableCountryInfo);
            let countryName = country.country
            if (country.border) {
                countryName = `${country.country} (${country.border})`
            }

            if (! isEmpty(country.data)){
                
                headers[0].push({name: countryName, html: countryName, agg: AggType.sum});
                headers[0].push({name: '', html: '', agg: AggType.sum});
                headers[0].push({name: '', html: '', agg: AggType.avg});
                headers[0].push({name: '', html: '', agg: AggType.avg});
                info.country = country.country;
                info.intradayResultId = country.intraday_result_id;
                info.exchange = country.exchange;

                headers[1].push(this.tableSubheaders.capacity);
                headers[1].push(this.tableSubheaders.capacity);
                headers[1].push(this.tableSubheaders.price);
                headers[1].push(this.tableSubheaders.price);

                headers[2].push(this.tableSubheaders.buyCapacity);
                info.buyCapacityIndex = ++index;

                headers[2].push(this.tableSubheaders.sellCapacity);
                info.sellCapacityIndex = ++index;

                headers[2].push(this.tableSubheaders.buyPrice);
                info.buyPriceIndex = ++index;

                headers[2].push(this.tableSubheaders.sellPrice);
                info.sellPriceIndex = ++index;

                headers[3].push(this.tableSubheaders.unit);
                headers[3].push(this.tableSubheaders.unit);

                if (info.country === 'GB'){
                    headers[3].push('GBP');
                    headers[3].push('GBP');
                }
                else{
                    headers[3].push(this.tableSubheaders.currency);
                    headers[3].push(this.tableSubheaders.currency);
                }

                country.data.forEach(hourly => {
                    values[hourly.hour].push(hourly.buy_capacity === '' ? '' : parseFloat(hourly.buy_capacity).toFixed(1));
                    values[hourly.hour].push(hourly.sell_capacity === '' ? '' : parseFloat(hourly.sell_capacity).toFixed(1));
                    values[hourly.hour].push(hourly.buy_price === '' ? '' : parseFloat(hourly.buy_price).toFixed(2));
                    values[hourly.hour].push(hourly.sell_price === '' ? '' : parseFloat(hourly.sell_price).toFixed(2));
                });

                intradayResultDetails.push(info);
            }


        });

        return { headers, values, intradayResultDetails };
    }

    onGetHeaderClassProfile = (colId, colCount) => {

        const id = (colId - 1) % colCount;

        return id ? this.headerClasses[Math.ceil(id / 3) % 2] : "";
    }

    onGetHeaderClassResults = (colId, colCount) => {

        const id = (colId - 1) % colCount;

        return id ? this.headerClasses[Math.ceil(id / 4) % 2] : "";
    }

    convertEmptyValuesToZero(tableData) {

        tableData.values.forEach(rowValues => {
            rowValues.forEach(function (value, i) {
                if (value === "")
                    rowValues[i] = 0;
            });
        });
    }

    validateTableInputs(tableData) {

        for (let rowValues of tableData.values) {
            for (let i = 1; i < rowValues.length; i++) {

                let value = rowValues[i];

                let info = tableData.intradayResultDetails.find(d => d.buyPriceIndex === i || d.sellPriceIndex === i
                    || d.buyCapacityIndex === i || d.sellCapacityIndex === i);

                if ((i === info.buyPriceIndex || i === info.sellPriceIndex) && value !== "") {

                    if (isNaN(value)) {
                        this.showErrorMessage(portalMessages.INTRADAY_RESULTS.INVALID_PRICE.replace('[PRICE]', this.maxPrice));
                        return false;
                    }

                    let v = parseFloat(value);

                    if (v > this.maxPrice) {
                        this.showErrorMessage(portalMessages.INTRADAY_RESULTS.INVALID_PRICE.replace('[PRICE]', this.maxPrice));
                        return false;
                    }

                } else if (value !== "") {

                    if (isNaN(value)) {
                        this.showErrorMessage(portalMessages.INTRADAY_RESULTS.INVALID_CAPACITY.replace('[CAPACITY]', this.maxCapacity));
                        return false;
                    }

                    let v = parseFloat(value);

                    if (v < 0 || v > this.maxCapacity) {
                        this.showErrorMessage(portalMessages.INTRADAY_RESULTS.INVALID_CAPACITY.replace('[CAPACITY]', this.maxCapacity));
                        return false;
                    }

                }
            }
        }

        return true;
    }

    createSaveIntradayResultsRequestBody(tableData) {

        let body = {
            counter_party: this.state.latestRequestedFilter.filter.counter_party,
            requested_date: this.state.latestRequestedFilter.filter.requested_date,
            results: []
        };

        tableData.intradayResultDetails.forEach(country => {

            let eachCountry = {
                country: country.country,
                exchange: country.exchange,
                intraday_result_id: country.intradayResultId,
                data: []
            };

            tableData.values.forEach(rowValues => {

                let hourly = { 'hour': 0, 'sell_capacity': 0, 'buy_capacity': 0, 'sell_price': 0, 'buy_price': 0 };

                hourly.hour = rowValues[0] - 1;
                hourly.sell_capacity = rowValues[country.sellCapacityIndex];
                hourly.buy_capacity = rowValues[country.buyCapacityIndex];
                hourly.sell_price = rowValues[country.sellPriceIndex];
                hourly.buy_price = rowValues[country.buyPriceIndex];

                eachCountry.data.push(hourly);
            });

            body.results.push(eachCountry);
        });

        return { filter: body };
    }

    callSaveIntradayResultsAsync = async (requestBody) => {

        this.spinner.showSpinner('callSaveIntradayResultsAsync');

        let result = false;

        try {
            let response;

            try {
                response = await saveIntradayResults(requestBody);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success)
                result = true;
            else if (response.data.error)
                this.showErrorMessage(response.data.error);
            else
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);

            return result;

        } finally {
            this.spinner.hideSpinner('callSaveIntradayResultsAsync');
        }
    }

    saveTableChangesAsync = async (tableToSave) => {

        this.spinner.showSpinner('saveTableChangesAsync');

        try {

            if (this.validateTableInputs(tableToSave)) {

                this.convertEmptyValuesToZero(tableToSave);

                let result = await this.callSaveIntradayResultsAsync(this.createSaveIntradayResultsRequestBody(tableToSave));

                if (result) {

                    alertSuccess(portalMessages.INTRADAY_RESULTS.SUCCESSFULL_SAVE);

                    await this.callGetIntradayResultsAsync(this.state.latestRequestedFilter)

                    return true;

                } else {
                    return false;
                }

            } else {
                return false;
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            return false;

        } finally {
            this.spinner.hideSpinner('saveTableChangesAsync');
        }
    }

    onResultTableDelete = () => {

        this.spinner.showSpinner('onResultTableDelete');

        let deleteRequestBody = { intraday_result_id: this.state.resultTable.intradayResultDetails[0].intradayResultId };

        deleteIntradayResults(deleteRequestBody)
            .then(response => {
                if (response.data.success) {

                    alertSuccess(portalMessages.INTRADAY_RESULTS.SUCCESSFUL_DELETE);

                    this.callGetIntradayResultsAsync(this.state.latestRequestedFilter);

                } else if (response.data.error) {
                    this.showErrorMessage(response.data.error);

                } else {
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('onResultTableDelete');
            });
    }

    isTableAlreadySaved() {
        return !isEmpty(this.state.resultTable) && this.state.resultTable.intradayResultDetails[0].intradayResultId ? true : false;
    }

    onShowButtonClick = () => {
        if (!this.state.selectedDate)
            alertError(portalMessages.DATE_SELECTION);

        else if (!this.state.selectedCounterParty)
            alertError(portalMessages.SELECT_COUNTER_PARTY);

        else
            this.callGetIntradayResultsAsync(this.createGetIntradayResultRequestBody());
    }

    onClearButtonClick = () => {
        if (this.state.selectedDate)
            this.setState({ selectedDate: null });
    }

    setCounterParty = selectedCounterParty => this.setState({ selectedCounterParty });

    setSelectedDate = selectedDate => this.setState({ selectedDate });

    onOpenEditMode = () => this.setState({ disableFilter: true });

    onCloseEditMode = () => this.setState({ disableFilter: false, resultTableByExcel: {} });

    checkCounterPartyFileExists() {
        let counterParty = this.state.latestRequestedFilter.filter ? this.state.latestRequestedFilter.filter.counter_party : null;

        if (counterParty)
            return counterParty === 'Energovia' || counterParty == 'Monolith Capital' || counterParty == 'Vitus Capital';

        return false;
    }

    onImportExcel(e) {

        this.spinner.showSpinner('onImportSpecialIntradayExcel');
        
        if (this.state.latestRequestedFilter.filter.counter_party === 'Monolith Capital' || this.state.latestRequestedFilter.filter.counter_party == 'Vitus Capital'){
            new IntradayResultsExcelHelper(e.target.files[0]).loadCapitalExcel(
                this.state.latestRequestedFilter.filter.counter_party,
                (excelData, countryResults) => this.updateTableAfterCapitalSpecialFileImport(excelData, countryResults)
            );
        }
        else{
            new IntradayResultsExcelHelper(e.target.files[0]).loadExcel(
                this.state.latestRequestedFilter.filter.counter_party,
                (excelData) => this.updateTableAfterSpecialFileImport(excelData)
            );
    
        }
    }

    updateTableAfterSpecialFileImport(excelData) {

        if (!isEmpty(excelData)) {

            let resultTableByExcel = {
                headers: [...this.state.resultTable.headers.map(i => [...i])],
                intradayResultDetails: [...this.state.resultTable.intradayResultDetails],
                values: []
            };

            this.state.resultTable.values.forEach((v, i) => resultTableByExcel.values.push([i + 1]));

            this.state.resultTable.intradayResultDetails.forEach(info => {

                if (Object.keys(excelData).includes(info.country)) {

                    excelData[info.country].buyCapacity.forEach(hourly => resultTableByExcel.values[hourly.h].push(hourly.v));
                    excelData[info.country].sellCapacity.forEach(hourly => resultTableByExcel.values[hourly.h].push(hourly.v));
                    excelData[info.country].buyPrice.forEach(hourly => resultTableByExcel.values[hourly.h].push(hourly.v));
                    excelData[info.country].sellPrice.forEach(hourly => resultTableByExcel.values[hourly.h].push(hourly.v));

                } else {
                    this.state.resultTable.values.forEach((otherCountryValue, i) =>
                        resultTableByExcel.values[i].push(otherCountryValue[info.buyCapacityIndex]));

                    this.state.resultTable.values.forEach((otherCountryValue, i) =>
                        resultTableByExcel.values[i].push(otherCountryValue[info.sellCapacityIndex]));

                    this.state.resultTable.values.forEach((otherCountryValue, i) =>
                        resultTableByExcel.values[i].push(otherCountryValue[info.buyPriceIndex]));

                    this.state.resultTable.values.forEach((otherCountryValue, i) =>
                        resultTableByExcel.values[i].push(otherCountryValue[info.sellPriceIndex]));
                }

            });

            this.setState({ resultTableByExcel }, () => {
                if (!this.tableRef.current)
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                else
                    this.tableRef.current.onOpenEditMode();
            });

        } else {
            this.showErrorMessage(portalMessages.EXCHANGE_RESULTS.SPECIAL_FILE_IMPORT_ERROR);
        }

        this.spinner.hideSpinner('onImportSpecialIntradayExcel');
    }

    updateTableAfterCapitalSpecialFileImport(excelData, countryResults) {

        if (!isEmpty(excelData)) {

            let capitalResultTable = {headers: ['Country', 'Operation Type', 'Start Date', 'End Date', 'Volume', 'Price', 'PNL'],
                                       values: [...excelData]}

            let resultTableByExcel = {
                headers: [...this.state.resultTable.headers.map(i => [...i])],
                intradayResultDetails: [...this.state.resultTable.intradayResultDetails],
                values: []
            };

            this.state.resultTable.values.forEach((v, i) => resultTableByExcel.values.push([i + 1]));

            this.state.resultTable.intradayResultDetails.forEach(info => {
                let countryIntradayResult = countryResults[info.country]; 
                if (! countryIntradayResult){
                    this.tableSpecificColumnList.forEach(infoType => {
                        this.state.resultTable.values.forEach((v, i) => {
                            resultTableByExcel.values[i].push(0);
                            resultTableByExcel.values[i].push(0);                            
                        })
    
                    })
                }
                else{
                    this.tableSpecificColumnList.forEach(infoType => {
                        Object.keys(countryIntradayResult).map(opType => {
                            let countryIntradayOpResult = countryIntradayResult[opType];
                            this.state.resultTable.values.forEach((v, i) => {
                                let hourResult = countryIntradayOpResult[i];
                                if (!hourResult){
                                    if (infoType === "Volume"){
                                        resultTableByExcel.values[i].push(0);
                                    }
                                    else{
                                        resultTableByExcel.values[i].push(0);    
                                    }
                                }
                                else{
                                    if (infoType === "Volume"){
                                        resultTableByExcel.values[i].push(hourResult["Volume"]);
                                    }
                                    else{
                                        resultTableByExcel.values[i].push(hourResult["Price"]);
                                    }
                                }
                            })
                        })
    
                    })
                }

            })

            this.setState({ resultTableByExcel, capitalResultTable }, () => {
                if (!this.tableRef.current)
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                else
                    this.tableRef.current.onOpenEditMode();
            });

        } else {
            this.showErrorMessage(portalMessages.EXCHANGE_RESULTS.SPECIAL_FILE_IMPORT_ERROR);
        }

        this.spinner.hideSpinner('onImportSpecialIntradayExcel');
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Intraday Results">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    disabled={this.state.disableFilter}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={this.setSelectedDate}
                                    maxDate={this.maxDate}
                                />
                            </div>
                        </div>
                       
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    disabled={this.state.disableFilter}
                                    isSearchable={false}
                                    options={this.counterPartyOptions}
                                    value={this.state.selectedCounterParty}
                                    defaultValue={this.state.selectedCounterParty}
                                    onSelectedOptionChange={this.setCounterParty}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableFilter}
                                className="btn v-cancel-button v-filter-button"
                                onClick={this.onClearButtonClick}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                            </button>
                        </div>
                    </VFilterContainer>
                    {
                        this.checkCounterPartyFileExists() &&
                        <React.Fragment>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-2">
                                    <VFileUploadIcon
                                        accept={['.xlsx', '.xls', '.xlsm']}
                                        labeltext={`Upload ${this.state.latestRequestedFilter.filter.counter_party} File`}
                                        key='importTableIcon'
                                        onFileChanged={(e) => this.onImportExcel(e)}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <VMainContainer flex>
                        <div className="d-flex flex-row">
                            {
                                !isEmpty(this.state.profileTable) &&
                                <div className="p-2">
                                    <VTable
                                        title="Intraday Profile"
                                        onGetHeaderClass={this.onGetHeaderClassProfile}
                                        customColumnClasses={this.tableHourColumnClass}
                                        simpleNumbers
                                        items={this.state.profileTable}
                                        showSpecialAgg
                                    />
                                </div>
                            }
                            <div className="p-2">
                                <VTable
                                    ref={this.tableRef}
                                    title="Intraday Results"
                                    onGetHeaderClass={this.onGetHeaderClassResults}
                                    customColumnClasses={this.tableHourColumnClass}
                                    readonlyColumns={[this.tableSubheaders.hour]}
                                    simpleNumbers
                                    inputType='number'
                                    items={isEmpty(this.state.resultTableByExcel) ? this.state.resultTable : this.state.resultTableByExcel}
                                    onSaveChangesAsync={this.saveTableChangesAsync}
                                    onOpenEditMode={this.onOpenEditMode}
                                    onCloseEditMode={this.onCloseEditMode}
                                    onTableDelete={this.onResultTableDelete}
                                    headerButtons={{
                                        showEditButton: true,
                                        showExportExcelButton: true,
                                        showImportExcelButton: true,
                                        showDeleteTableButton: this.isTableAlreadySaved()
                                    }}
                                    showSpecialAgg
                                />
                            </div>
                            {
                                !isEmpty(this.state.capitalResultTable) &&
                                <div className="p-2">
                                    <VTable
                                        title="Hourly Intraday Details"
                                        simpleNumbers
                                        items={this.state.capitalResultTable}
                                    />
                                </div>
                            }
                        </div>

                    </VMainContainer>
                </VContentContainer>
                {
                    this.state.showNeedRefreshModal &&
                    <NeedRefreshModal
                        show={this.state.showNeedRefreshModal}
                        message={portalMessages.INTRADAY_RESULTS.COULD_NOT_GET_RESULTS}

                    />
                }
            </React.Fragment>
        );
    }
}


export default IntradayResults;