import axios from 'axios';
import store from '../store';
import { handleApiError } from '../helpers/errorHelper';
import { SET_USER } from '../actions/types';
import { redirectToLogin } from '../helpers/routeHelper';
import vitusETRMapi from '../etrm/api/apiConfig';

export const endpointNames = {
    login: '/login',
    logout: '/logout',
    main: '/',
    get_user: '/get_user',
    get_borders: '/getBorders',
    get_margin_analysis: '/getMarginAnalysis',
    get_bid_parameter: '/getBidParameter',
    get_safety_margin: '/getSafetyMargin',
    get_auction_bids: '/getAuctionBids',
    getInitialBids: '/getInitialBids',
    saveAuctionBids: '/saveAuctionBids',
    deleteBid: '/deleteBid',
    sendAuctionBids: '/sendAuctionBids',
    get_auction_allocations: '/getAuctionAllocations',
    save_auction_allocations: '/saveAllocation',
    delete_auction_allocations: '/deleteAllocation',
    get_offer_parameters: '/getOfferParameters',
    getExchangeOffers: '/getExchangeOffers',
    saveExchangeOffers: '/saveExchangeOffers',
    getObligedAmounts: '/getObligedAmounts',
    saveObligedAmounts: '/saveObligedAmounts',
    get_exhange_results: '/getExchangeResults',
    save_exhange_results: '/saveExchangeResults',
    delete_exchange_result: '/deleteExchangeResult',
    sendExchangeOffers: '/sendExchangeOffers',
    deleteObligedAmounts: '/deleteObligedAmounts',
    getExchangeMatrix: '/getExchangeMatrix',
    getHorizontalTable: '/getHorizontalTable',
    getNextLevelOffers: '/getNextLevelOffers',
    deleteOffer: '/deleteOffer',
    getNominations: '/getNominations',
    saveNominations: '/saveNominations',
    deleteNominations: '/deleteNominations',
    saveIntradayProfiles: '/saveIntradayProfiles',
    deleteIntradayProfiles: '/deleteIntradayProfiles',
    sendNominations: '/sendNominations',
    getLtNominations: '/getLtNominations',
    get_tso: '/getTSO',
    get_lt_auction_allocation: '/getLTAllocation',
    save_lt_auction_allocation: '/saveLTAllocation',
    delete_lt_auction_allocation: '/deleteLTAllocation',
    getPnlBorders: '/getPnlBorders',
    getPnl: '/getPnl',
    downloadPnlFile: '/downloadPnlFile',
    save_custom_pnl: '/saveCustomPnl',
    get_custom_pnl: '/getCustomPnl',
    delete_custom_pnl: '/deleteCustomPnl',
    get_intraday_results: '/getIntradayResults',
    save_intraday_results: '/saveIntradayResults',
    delete_intraday_results: '/deleteIntradayResults',
    get_service_cost_types: '/getServiceCostTypes',
    get_selected_service_costs:'/getSelectedServiceCosts',
    save_selected_service_costs: '/saveSelectedServiceCosts',
    getForecastCountry: '/getForecastCountry',
    getPriceForecasts: '/getPriceForecasts',
    savePriceForecasts: '/savePriceForecasts',
    getCompanyNominations: '/getCompanyNominations',
    getCompanyNominationsFile: '/getCompanyNominationsFile',
    sendCompanyNominationsFile: '/sendCompanyNominationsFile',
    getCounterPartyDailyEnergyCosts: '/getCounterPartyDailyEnergyCosts',
    getCounterPartyDailyEnergyCostsFile: '/getCounterPartyDailyEnergyCostsFile',
    getCompanyInvoice: '/getCompanyInvoice',
    getCompanyInvoiceFile: '/getCompanyInvoiceFile',
    getCrossborderInvoice: '/getCrossborderInvoice',
    savePaymentDetails: '/savePaymentDetails',
    saveCustomInvoice: '/saveCustomInvoice',
    saveExchangeRates: '/saveExchangeRates',
    saveHourlyExchangeRates: '/saveHourlyExchangeRates',
    getCrossborderInvoiceFile: '/getCrossborderInvoiceFile',
    getResaleRevenue: '/getResaleRevenue',
    getResaleRevenueFile: '/getResaleRevenueFile',
    getLTAllocationMaxCapacities: '/getLTAllocationMaxCapacities',
    getInvoiceSummary: '/getInvoiceSummary',
    getInvoiceSummaryFile: 'getInvoiceSummaryFile'
};

export const vitusApi = axios.create({
    baseURL: process.env.REACT_APP_VITUS_API,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true, //TODO: research again after CORS
    validateStatus: (status) => { return status === 200; }
});

export const login = async (body) => { return vitusApi.post(endpointNames.login, body) };

export const logout = async () => { return vitusApi.post(endpointNames.logout) };

export const getUser = async () => {
    try {
        const response = await vitusApi.get(endpointNames.get_user);

        //Check if it everything is provided
        if (!response.data
            || !response.data.data
            || response.data.data.user_id === undefined
            || !response.data.data.user_name
            || !response.data.data.roles
            || response.data.data.roles.length <= 0
            || !response.data.data.companies
            || response.data.data.companies.length <= 0
            || !response.data.data.pages
            || response.data.data.pages.length <= 0
            || !response.data.data.csrf)
            throw Error();

        const user = {
            userId: response.data.data.user_id,
            userName: response.data.data.user_name,
            roles: response.data.data.roles,
            pages: response.data.data.pages,
            companies: response.data.data.companies,
            isCompanySelectable: response.data.data.display_company_option,
        };

        vitusApi.defaults.headers.common['X-Vitus-Token'] = response.data.data.csrf;
        vitusETRMapi.defaults.headers.common['X-Vitus-Token'] = response.data.data.csrf;

        store.dispatch({ type: SET_USER, payload: { ...user, userChecked: true } });

        return true;
    } catch (error) {
        store.dispatch({ type: SET_USER, payload: { userChecked: true } });

        handleApiError(error);
        redirectToLogin();
        return false;
    }
}

export const getBorders = async (filter) => { return vitusApi.get(endpointNames.get_borders, { params: filter }) };

export const getMarginAnalysis = async (body) => { return vitusApi.post(endpointNames.get_margin_analysis, body) };

export const getBidParameter = async () => { return vitusApi.get(endpointNames.get_bid_parameter) };

export const getSafetyMargin = async () => { return vitusApi.get(endpointNames.get_safety_margin) };

export const getAuctionBids = async (filter) => { return vitusApi.post(endpointNames.get_auction_bids, filter) };

export const getInitialBids = async (filter) => { return vitusApi.post(endpointNames.getInitialBids, filter) };

export const saveAuctionBids = async (filter) => { return vitusApi.post(endpointNames.saveAuctionBids, filter) };

export const deleteBid = async (filter) => { return vitusApi.post(endpointNames.deleteBid, filter) };

export const sendAuctionBids = async (filter) => { return vitusApi.post(endpointNames.sendAuctionBids, filter) };

export const getAuctionAllocations = async (body) => { return vitusApi.post(endpointNames.get_auction_allocations, body) };

export const saveAuctionAllocations = async (body) => { return vitusApi.post(endpointNames.save_auction_allocations, body) };

export const deleteAuctionAllocation = async (body) => { return vitusApi.post(endpointNames.delete_auction_allocations, body) };

export const getOfferParameters = async () => { return vitusApi.get(endpointNames.get_offer_parameters) };

export const getExchangeOffers = async (filter) => { return vitusApi.post(endpointNames.getExchangeOffers, filter) };

export const saveExchangeOffers = async (filter) => { return vitusApi.post(endpointNames.saveExchangeOffers, filter) };

export const getObligedAmounts = async (filter) => { return vitusApi.post(endpointNames.getObligedAmounts, filter) };

export const saveObligedAmounts = async (filter) => { return vitusApi.post(endpointNames.saveObligedAmounts, filter) };

export const getExchangeResults = async (body) => { return vitusApi.post(endpointNames.get_exhange_results, body) };

export const saveExchangeResults = async (body) => { return vitusApi.post(endpointNames.save_exhange_results, body) };

export const deleteExchangeResult = async (body) => { return vitusApi.post(endpointNames.delete_exchange_result, body) };

export const sendExchangeOffers = async (filter) => { return vitusApi.post(endpointNames.sendExchangeOffers, filter) };

export const deleteObligedAmounts = async (filter) => { return vitusApi.post(endpointNames.deleteObligedAmounts, filter) };

export const getExchangeMatrix = async (filter) => { return vitusApi.post(endpointNames.getExchangeMatrix, filter) };

export const getHorizontalTable = async (filter) => { return vitusApi.post(endpointNames.getHorizontalTable, filter) };

export const getNextLevelOffers = async (filter) => { return vitusApi.post(endpointNames.getNextLevelOffers, filter) };

export const deleteOffer = async (filter) => { return vitusApi.post(endpointNames.deleteOffer, filter) };

export const getNominations = async (filter) => { return vitusApi.post(endpointNames.getNominations, filter) };

export const saveNominations = async (filter) => { return vitusApi.post(endpointNames.saveNominations, filter) };

export const deleteNominations = async (filter) => { return vitusApi.post(endpointNames.deleteNominations, filter) };

export const saveIntradayProfiles = async (filter) => { return vitusApi.post(endpointNames.saveIntradayProfiles, filter) };

export const deleteIntradayProfiles = async (filter) => { return vitusApi.post(endpointNames.deleteIntradayProfiles, filter) };

export const sendNominations = async (filter) => { return vitusApi.post(endpointNames.sendNominations, filter) };

export const getLtNominations = async (filter) => { return vitusApi.post(endpointNames.getLtNominations, filter) };

export const getTSO = async () => { return vitusApi.get(endpointNames.get_tso) };

export const getLTAuctionAllocation = async (body) => { return vitusApi.post(endpointNames.get_lt_auction_allocation, body) };

export const saveLTAuctionAllocation = async (body) => { return vitusApi.post(endpointNames.save_lt_auction_allocation, body) };

export const deleteLTAuctionAllocation = async (body) => { return vitusApi.post(endpointNames.delete_lt_auction_allocation, body) };

export const getPnlBorders = async () => { return vitusApi.get(endpointNames.getPnlBorders) };

export const getPnl = async (filter) => { return vitusApi.post(endpointNames.getPnl, filter) };

export const downloadPnlFile = async (filter) => { return vitusApi.get(endpointNames.downloadPnlFile, { responseType: 'arraybuffer', params: filter }) };

export const saveCustomPnl = async (body) => { return vitusApi.post(endpointNames.save_custom_pnl, body) };

export const getCustomPnl = async (body) => { return vitusApi.post(endpointNames.get_custom_pnl, body) };

export const deleteCustomPnl = async (body) => { return vitusApi.post(endpointNames.delete_custom_pnl, body) };

export const getIntradayResults = async (body) => { return vitusApi.post(endpointNames.get_intraday_results, body) };

export const saveIntradayResults = async (body) => { return vitusApi.post(endpointNames.save_intraday_results, body) };

export const deleteIntradayResults = async (body) => { return vitusApi.post(endpointNames.delete_intraday_results, body) };

export const getServiceCostTypes = async () => { return vitusApi.get(endpointNames.get_service_cost_types) };

export const getSelectedServiceCosts = async (body) => { return vitusApi.post(endpointNames.get_selected_service_costs, body) };

export const saveSelectedServiceCosts = async (body) => { return vitusApi.post(endpointNames.save_selected_service_costs, body) };

export const getForecastCountry = async () => { return vitusApi.get(endpointNames.getForecastCountry) };

export const getPriceForecasts = async (filter) => { return vitusApi.post(endpointNames.getPriceForecasts, filter) };

export const savePriceForecasts = async (body) => { return vitusApi.post(endpointNames.savePriceForecasts, body) };

export const getCompanyNominations = async (filter) => { return vitusApi.post(endpointNames.getCompanyNominations, filter) };

export const getCompanyNominationsFile = async (body) => {return vitusApi.post(endpointNames.getCompanyNominationsFile, body, {responseType: 'arraybuffer'})};

export const sendCompanyNominationsFile = async (body) => {return vitusApi.post(endpointNames.sendCompanyNominationsFile, body)};

export const getCounterPartyDailyEnergyCosts = async (filter) => { return vitusApi.post(endpointNames.getCounterPartyDailyEnergyCosts, filter) };

export const getCounterPartyDailyEnergyCostsFile = async (body) => { return vitusApi.post(endpointNames.getCounterPartyDailyEnergyCostsFile, body, {responseType: 'arraybuffer'})};

export const getCompanyInvoice = async (filter) => { return vitusApi.post(endpointNames.getCompanyInvoice, filter) };

export const getCompanyInvoiceFile = async (body) => {return vitusApi.post(endpointNames.getCompanyInvoiceFile, body, {responseType: 'arraybuffer'})};

export const getCrossborderInvoice = async (filter) => { return vitusApi.post(endpointNames.getCrossborderInvoice, filter) };

export const savePaymentDetails = async (filter) => { return vitusApi.post(endpointNames.savePaymentDetails, filter) };

export const saveCustomInvoice = async (filter) => { return vitusApi.post(endpointNames.saveCustomInvoice, filter) };

export const saveExchangeRates = async (filter) => { return vitusApi.post(endpointNames.saveExchangeRates, filter) };

export const getCrossborderInvoiceFile = async (body) => {return vitusApi.post(endpointNames.getCrossborderInvoiceFile, body, {responseType: 'arraybuffer'})};

export const saveHourlyExchangeRates = async (filter) => { return vitusApi.post(endpointNames.saveHourlyExchangeRates, filter) };

export const getResaleRevenue = async (filter) => { return vitusApi.post(endpointNames.getResaleRevenue, filter) };

export const getResaleRevenueFile = async (body) => {return vitusApi.post(endpointNames.getResaleRevenueFile, body, {responseType: 'arraybuffer'})};

export const getLTAllocationMaxCapacities = async (filter) => { return vitusApi.post(endpointNames.getLTAllocationMaxCapacities, filter) };

export const getInvoiceSummary = async (filter) => { return vitusApi.post(endpointNames.getInvoiceSummary, filter) };

export const getInvoiceSummaryFile = async (body) => {return vitusApi.post(endpointNames.getInvoiceSummaryFile, body, {responseType: 'arraybuffer'})};
